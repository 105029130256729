import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import Image from '@nubank/nuds-web/components/Image/Image';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: 125vh; 
  position: fixed; 

  ${breakpointsMedia({
    lg: css`
        width: 50px;
        padding: 9px;
    `,
  })}
`;

const FixedBanner = ({ imageSrc, label }) => {
  const { formatMessage } = useIntl();
  const textBanner = useMemo(() => (label ? formatMessage({ id: label }) : ''), [label, formatMessage]);
  return label ? (
    <BannerContainer>
      <Image
        src={imageSrc}
        loading="lazy"
        alt={textBanner}
        title={textBanner}
        webp
      />
    </BannerContainer>
  ) : null;
};

FixedBanner.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FixedBanner;
